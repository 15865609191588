// import { useDispatch, useSelector } from 'react-redux';
// import { useEffect, useState } from 'react';
// import { fetchToGetLoggedInUserDetails } from './reduxStore/authentication/userAuthSlice';
// import Login from './assets/Login';
// import Admin from './modules/Admin/Admin';
// import NavigateInJamPreparation from './modules/JamPreparation/NavigateInJamPreparation';
// import NavigateInLcPreparation from './modules/LcPreparation/NavigateInLcPreparation';
// import NavigateInLcRejection from './modules/LcRejection/NavigateInLcRejection';
// import NavigateInBagInoculation from './modules/BagInoculation/NavigateInBagInoculation';
// import NavigateInBagStoreRoom from './modules/BagStoreRoom/NavigateInBagStoreRoom';
// import NavigateInMouldFilling from './modules/MouldFilling/NavigateInMouldFilling';
// import NavigateInDeMoulding from './modules/DeMouldingPieces/NavigateInDeMoulding.jsx';
// import NavigateGrowthChamber from './modules/GrowthChamber/NavigateGrowthChamber';
// import NavigateInOvenIn from './modules/OvenIn/NavigateInOvenIn';
// import NavigateInOvenOut from './modules/OvenOut/NavigateInOvenOut';
// import NavigateInMouldRejection from './modules/MouldRejection/NavigateInMouldRejection.jsx';
// import NavigateInQrCodeGeneration from './modules/QrCodeGeneration/NavigateInQrCodeGeneration.jsx';
// import SkeletonLoader from './components/SkeletonLoader.jsx';
// import DashboardSkeleton from './components/DashboardSkeleton.jsx';

// function App() {
//   const state = useSelector((state) => state.userAuthReducer);
//   const dispatch = useDispatch();
//   const [isLoggedIn, setIsLoggedIn] = useState(false); 
//   const [userRole, setUserRole] = useState(null);

//   useEffect(() => {
//     dispatch(fetchToGetLoggedInUserDetails());
//   }, [dispatch , userRole]);

//   useEffect(() => {
//     setIsLoggedIn(!!state.user?.dhaDbTkn);
//     setUserRole(state.user?.loggedInUser?.role);
//   }, [state ,userRole]);

 
  

//   const renderRoutes = () => {
//     switch (userRole) {
//       case "Admin":
//         return <Admin />;
//       case "Supervisor (Jam Preparation)":
//         return <NavigateInJamPreparation />;
//       case "Supervisor (LC Preparation)":
//         return <NavigateInLcPreparation />;
//       case "Supervisor (LC Rejection)":
//         return <NavigateInLcRejection />;
//       case "Supervisor (Bag Inoculation)":
//         return <NavigateInBagInoculation />;
//       case "Supervisor (Bag Incubation)":
//         return <NavigateInBagStoreRoom />;
//       case "Supervisor (Mould Filling)":
//         return <NavigateInMouldFilling />;
//       case "QR Code Generator":
//         return <NavigateInQrCodeGeneration />;
//       case "Supervisor (Mould Rejection)":
//         return <NavigateInMouldRejection />;
//       case "Supervisor (De-Moulding)":
//         return <NavigateInDeMoulding />;
//       case "Supervisor (Growth Chamber)":
//         return <NavigateGrowthChamber />;
//       case "Supervisor (Oven In)":
//         return <NavigateInOvenIn />;
//       case "Supervisor (Oven Out)":
//         return <NavigateInOvenOut />;
//       default:
//         return <DashboardSkeleton/>
//     }
//   };

//   const loggedIn = async (dhaDbTkn , enteredRole) => {
//     setIsLoggedIn(!!dhaDbTkn);
//     await dispatch(fetchToGetLoggedInUserDetails());
//     setUserRole(enteredRole)
//     renderRoutes()
//   }

//   useEffect(() => {
//     renderRoutes()
//   }, [isLoggedIn, state, loggedIn,userRole]);
//   // console.log("isLoggedIn",isLoggedIn);
  


//   return (
//     <>
//       {isLoggedIn ? renderRoutes() : <Login loggedIn={loggedIn}/>}
//     </>
//   );
// }

// export default App; 


import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchToGetLoggedInUserDetails } from "./reduxStore/authentication/userAuthSlice";
import { useNavigate } from "react-router-dom";
import Login from "./assets/Login";
import DashboardSkeleton from "./components/DashboardSkeleton.jsx";
import Admin from "./modules/Admin/Admin";
import NavigateInJamPreparation from "./modules/JamPreparation/NavigateInJamPreparation";
import NavigateInLcPreparation from "./modules/LcPreparation/NavigateInLcPreparation";
import NavigateInLcRejection from "./modules/LcRejection/NavigateInLcRejection";
import NavigateInBagInoculation from "./modules/BagInoculation/NavigateInBagInoculation";
import NavigateInBagStoreRoom from "./modules/BagStoreRoom/NavigateInBagStoreRoom";
import NavigateInMouldFilling from "./modules/MouldFilling/NavigateInMouldFilling";
import NavigateInDeMoulding from "./modules/DeMouldingPieces/NavigateInDeMoulding.jsx";
import NavigateGrowthChamber from "./modules/GrowthChamber/NavigateGrowthChamber";
import NavigateInOvenIn from "./modules/OvenIn/NavigateInOvenIn";
import NavigateInOvenOut from "./modules/OvenOut/NavigateInOvenOut";
import NavigateInMouldRejection from "./modules/MouldRejection/NavigateInMouldRejection.jsx";
import NavigateInQrCodeGeneration from "./modules/QrCodeGeneration/NavigateInQrCodeGeneration.jsx";

function App() {
  const state = useSelector((state) => state.userAuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // NEW: Loading state

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      await dispatch(fetchToGetLoggedInUserDetails());
      setIsLoading(false);
    };

    fetchUserDetails();
  }, [dispatch]);

  useEffect(() => {
    if (state.user?.dhaDbTkn) {
      setIsLoggedIn(true);
      setUserRole(state.user?.loggedInUser?.role);
    }
  }, [state.user]);

  const renderRoutes = () => {
    if (isLoading) return <DashboardSkeleton />; // Show skeleton while loading

    switch (userRole) {
      case "Admin":
        return <Admin />;
      case "Supervisor (Jam Preparation)":
        return <NavigateInJamPreparation />;
      case "Supervisor (LC Preparation)":
        return <NavigateInLcPreparation />;
      case "Supervisor (LC Rejection)":
        return <NavigateInLcRejection />;
      case "Supervisor (Bag Inoculation)":
        return <NavigateInBagInoculation />;
      case "Supervisor (Bag Incubation)":
        return <NavigateInBagStoreRoom />;
      case "Supervisor (Mould Filling)":
        return <NavigateInMouldFilling />;
      case "QR Code Generator":
        return <NavigateInQrCodeGeneration />;
      case "Supervisor (Mould Rejection)":
        return <NavigateInMouldRejection />;
      case "Supervisor (De-Moulding)":
        return <NavigateInDeMoulding />;
      case "Supervisor (Growth Chamber)":
        return <NavigateGrowthChamber />;
      case "Supervisor (Oven In)":
        return <NavigateInOvenIn />;
      case "Supervisor (Oven Out)":
        return <NavigateInOvenOut />;
      default:
        return <DashboardSkeleton />;
    }
  };

  const loggedIn = async (dhaDbTkn, enteredRole) => {
    setIsLoggedIn(true);
    setUserRole(enteredRole);
    await dispatch(fetchToGetLoggedInUserDetails());
    navigate("/"); // Redirect after login
  };

  return (
    <>
      {isLoading ? <DashboardSkeleton /> : isLoggedIn ? renderRoutes() : <Login loggedIn={loggedIn} />}
    </>
  );
}

export default App;
