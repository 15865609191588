import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import DeMouldNewPiece from './DeMouldNewPiece'
import DataOfDeMouldingPieces from './DataOfDeMouldingPieces'
import Logout from '../../assets/Logout'
import Login from '../../assets/Login'

const NavigateInDeMoulding = () => {

 return (
    <>
       {/* <BrowserRouter> */}
      <Navbar moduleName={"De-Moulding Pieces"} home={"/"} viewData={"/viewDataOfDeMouldingPieces"}/>
        <Routes>
          <Route path="/" element={<DeMouldNewPiece />}/>
          <Route path="/viewDataOfDeMouldingPieces" element={< DataOfDeMouldingPieces/>}/>
          <Route path="/myProfile" element={< ProfileCard />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/logout" element={<Logout />}/>
        </Routes>
      {/* </BrowserRouter> */}
    </>
  )
}

export default NavigateInDeMoulding
