import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import InoculateNewBag from './InoculateNewBag'
import DataOfBagInoculation from './DataOfBagInoculation'
import Login from '../../assets/Login'
import Logout from '../../assets/Logout'
import Footer from '../Footer'

const NavigateInBagInoculation = () => {
  return (
    <>

      {/* <BrowserRouter> */}
      <Navbar moduleName={"Bag Inoculation"} home={"/"} viewData={"/viewDataOfBagInoculation"}/>
        <Routes>
          <Route path="/" element={<InoculateNewBag />}/>
          <Route path="/viewDataOfBagInoculation" element={< DataOfBagInoculation/>}/>
          <Route path="/myProfile" element={< ProfileCard />} />    
          <Route path="/login" element={<Login />}/>
          <Route path="/logout" element={<Logout />}/>

        </Routes>

      {/* </BrowserRouter> */}
    </>
  )
}

export default NavigateInBagInoculation
