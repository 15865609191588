import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from "chart.js";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../assets/Loader";
import BarChartSkeleton from "../../../components/BarChartSkeleton";
import TableSkeleton from "../../../components/TableSkeleton ";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


const AllModuleDataChart = () => {
    const [allModuleDataCount, setAllModuleDataCount] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (date) => date.toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const [selectedButton, setSelectedButton] = useState('Today'); // Track the active button


    const handleToday = () => {
        const today = new Date();
        const formattedDate = formatDate(today);
        setStartDate(formattedDate);
        setEndDate(formattedDate);
        setSelectedButton('Today');
        getAllModulesData(formattedDate, formattedDate);
    };

    const handleYesterday = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const formattedDate = formatDate(yesterday);
        setStartDate(formattedDate);
        setEndDate(formattedDate);
        setSelectedButton('Yesterday');
        getAllModulesData(formattedDate, formattedDate);
    };
    const handleAllTime = () => {
        const dayOne = '2024-10-21';
        const today = new Date();
        const formattedDate = formatDate(today);
        setStartDate(dayOne);
        setEndDate(formattedDate);
        setSelectedButton('AllTime');
        getAllModulesData(dayOne, formattedDate);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (startDate && endDate) {
            setSelectedButton('Custom');
            getAllModulesData(startDate, endDate);
        }
    };

    const getAllModulesData = async (startDate, endDate) => {
        // const [isloading, setIsLoading] = useState(false);

        setIsLoading(true)

        const url = `${process.env.REACT_APP_BACKEND_PORT_AND_HOST}api/v1/admin/getDataOfAllModules?startDate=${startDate}&endDate=${endDate}`;
        const response = await fetch(url, {
            method: "GET",
            credentials: "include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            throw new Error("Failed to fetch data of all modules");
        }
        const data = await response.json();
        // console.log("res data", data);
        setAllModuleDataCount(data.allModuleData)
        // return data;
        setIsLoading(false)
    }

    useEffect(() => {
        getAllModulesData(startDate, endDate);
        //    console.log("startDate: ", startDate)
        //    console.log("endDate: ", endDate)
    }, []);

    const labels = [
        "Jam Preparation",

        "LC Preparation", "LC Rejection",

        "Bag Inoculation", "Bag Incubation",

        "Mould Filling", "Bags Used", "Rejected Mould",

        "De-Moulding Pieces",

        "Humidifier's Batch", "Pieces in Humidifier",

        "Oven's Batch", "Oven In Pieces", "Oven Out Pieces"
    ];

    const barChartData = {
        labels: labels,
        datasets: [
            {
                label: "Pieces made by different departments.",
                data: [
                    // ? Jam
                    allModuleDataCount.totalCountDataOfPreparedJam || 0,

                    // ? LC
                    allModuleDataCount.totalCountDataOfPreparedLc || 0,
                    allModuleDataCount.totalCountDataOfRejectedLc || 0,

                    // ? Bag
                    allModuleDataCount.totalCountDataOfBagInoculationData || 0,
                    allModuleDataCount.totalCountDataOfRejectedBag || 0,


                    // ? Mould
                    allModuleDataCount.totalCountDataOfMouldFillingData || 0,
                    allModuleDataCount.bagScannedInMouldFillingCount || 0,
                    allModuleDataCount.mouldRejectionCount || 0,

                    // ? De-mould
                    allModuleDataCount.totalCountDataOfDeMouldedPiece || 0,

                    // ? Humidifier
                    allModuleDataCount.humidifierBatchCount || 0,
                    allModuleDataCount.humidifierCount || 0,


                    // ? Oven
                    allModuleDataCount.ovenInBatchCount || 0,
                    allModuleDataCount.ovenInCount || 0,
                    allModuleDataCount.ovenOutCount || 0,

                ],
                backgroundColor: [

                    "rgba(255, 87, 51, 0.9)",
                    "rgba(60, 179, 113, 0.9)",
                    "rgba(255, 140, 0, 0.9)",
                    "rgba(70, 130, 180, 0.9)",
                    "rgba(220, 20, 60, 0.9)",
                    "rgba(144, 238, 144, 0.9)",
                    "rgba(0, 191, 255, 0.9)",
                    "rgba(255, 69, 0, 0.9)",
                    "rgba(138, 43, 226, 0.9)",
                    "rgba(255, 215, 0, 0.9)",
                    "rgba(32, 178, 170, 0.9)",
                    "rgba(218, 112, 214, 0.9)",
                    "rgba(127, 255, 212, 0.9)",
                    "rgba(176, 224, 230, 0.9)"



                ],
                borderColor: [
                    "rgba(255, 87, 51, 0.9)",
                    "rgba(60, 179, 113, 0.9)",
                    "rgba(255, 140, 0, 0.9)",
                    "rgba(70, 130, 180, 0.9)",
                    "rgba(220, 20, 60, 0.9)",
                    "rgba(144, 238, 144, 0.9)",
                    "rgba(0, 191, 255, 0.9)",
                    "rgba(255, 69, 0, 0.9)",
                    "rgba(138, 43, 226, 0.9)",
                    "rgba(255, 215, 0, 0.9)",
                    "rgba(32, 178, 170, 0.9)",
                    "rgba(218, 112, 214, 0.9)",
                    "rgba(127, 255, 212, 0.9)",
                    "rgba(176, 224, 230, 0.9)"
                ],
                borderWidth: 1,
            },
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
            },
            title: {
                display: true,
            },
        },
    }
    return (
        <>
            <div className="flex justify-center mt-2 p-4 my-4 mx-auto shadow border border-gray rounded">
                {/* <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">All Modules</span> */}


                <div className="flex flex-wrap flex-center item-center">
                    <button
                        onClick={handleToday}
                        className={`
                                ${selectedButton === 'Today' ?
                                ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                            }`}
                    >
                        Today
                    </button>

                    <button
                        onClick={handleYesterday}
                        className={`
                        ${selectedButton === 'Yesterday' ?
                                ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                            }`}
                    >
                        Yesterday
                    </button>

                    <button
                        onClick={handleAllTime}
                        className={`
                            ${selectedButton === 'AllTime' ?
                                ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                            }
                        `}
                    >
                        All Time
                    </button>

                    <div className="flex">

                        <span className="ml-1 text-gray-900 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">Starting Date:</span>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className={`
                        ${selectedButton === 'Custom' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                }`}
                        />
                    </div>

                    <div className="flex">

                        <span className="ml-1 text-gray-900 rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">End Date:</span>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className={`
                        ${selectedButton === 'Custom' ?
                                    ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                                    : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'
                                }`}
                        />
                    </div>

                    <button type="button"
                        onClick={handleSubmit}
                        className={`${selectedButton === 'Custom' ?
                            ' ml-1 text-white bg-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-white dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'
                            : 'ml-1 text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800'}`}>
                        Apply
                    </button>


                </div>

            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {/* <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">All Modules</span> */}
                <table className="w-full text-sm text-left text-gray-700">
                    <thead className="text-xs uppercase bg-gray-100">
                        <tr>
                            {labels.map((currItem) => (<th scope="col" className="px-6 py-3">
                                {currItem}
                            </th>))}
                            <th scope="col" className="px-6 py-3">
                                Total
                            </th>

                        </tr>
                    </thead>
                    <tbody>


                        <tr className="bg-white">
                            {isLoading ? (
                                <td colSpan="14" className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                    {/* <Loader /> */}
                                    <TableSkeleton columns={15} rows={1} />
                                </td>
                            ) : (
                                <>
                                    {labels.map((label, index) => (
                                        <>
                                        <td key={index} className="px-6 py-4">
                                            {barChartData.datasets[0].data[index]}
                                        </td>
                                       
                                     </>
                                    ))}
                                      <td className="px-6 py-4">
                                         {barChartData.datasets[0].data.reduce((accumulator, nextValue) => accumulator + nextValue, 0)}
                                     </td>
                                </>
                            )}


                        </tr>
                    </tbody>
                </table>
            </div>


            <div className=" p-4 my-4 mx-auto shadow border border-gray rounded relative ">

                <div className="flex justify-center max-h-[650px]">
                {!isLoading ? ( <Bar options={options} data={barChartData} />) : ( <BarChartSkeleton/>) }
                </div>




                <NavLink to="/admin/dashboard" className={({ isActive }) =>
                    isActive ? "hidden" : "absolute bottom-0 right-0 m-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                }
                >
                    Analytics
                    <svg className="ml-2 rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </NavLink>
            </div>


        </>
    )
}

export default AllModuleDataChart