import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import QrCodeGeneration from './index'
import Logout from '../../assets/Logout'
import Login from '../../assets/Login'

const NavigateInQrCodeGeneration = () => {
  
  return (
    <>
       {/* <BrowserRouter> */}
      <Navbar moduleName={"QR Code Generator"} home={"/"} showData={false} />
        <Routes>
          <Route path="/" element={<QrCodeGeneration />}/>
          <Route path="/myProfile" element={< ProfileCard />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/logout" element={<Logout />}/>
        </Routes>
      {/* </BrowserRouter> */}
    </>
  )
}

export default NavigateInQrCodeGeneration
