import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import PlacePieces from './PlacePieces'
import TakeReading from './TakeReadings'
import Humidifier from './Humidifier'
import Login from '../../assets/Login'
import Logout from '../../assets/Logout'
import RemoveMould from './RemoveMould'
import HumidifiersData from './HumidifiersData'
import GrowthData from './GrowthData'


const NavigateInGrowthChamber = () => {
    return (
        <>
            {/* <BrowserRouter> */}
                <Navbar moduleName={"Growth Chamber"} home={"/"} viewData={"/viewHumidifiersData"} />
            {/* <PlaceMouldInHumidifier/> */}
                <Routes>
                    <Route path="/" element={<Humidifier/> } />
                    <Route path="/placeNewPieces" element={<PlacePieces />} />
                    <Route path="/reading" element={<TakeReading />} />
                    <Route path="/remove" element={<RemoveMould />} />
                    <Route path="/viewHumidifiersData" element={< HumidifiersData />} />
                    <Route path="/growthData" element={< GrowthData />} />
                    
                    <Route path="/myProfile" element={< ProfileCard />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                </Routes>
            {/* </BrowserRouter> */}
        </>
    )
}

export default NavigateInGrowthChamber
