import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import RejectNewBag from './RejectNewBag'
import DataOfBagStoreRoom from './DataOfBagStoreRoom'
import Login from '../../assets/Login'
import Logout from '../../assets/Logout'
import Footer from '../Footer'

const NavigateInBagStoreRoom = () => {
    return (
        <>
    
          {/* <BrowserRouter> */}
          <Navbar moduleName={"Bag Incubation"} home={"/"} viewData={"/viewDataOfBagIncubation"}/>
            <Routes>
              <Route path="/" element={<RejectNewBag />}/>
              <Route path="/viewDataOfBagIncubation" element={< DataOfBagStoreRoom/>}/>
              <Route path="/myProfile" element={< ProfileCard />} />
              <Route path="/login" element={<Login />}/>
              <Route path="/logout" element={<Logout />}/>
            </Routes>
       
          {/* </BrowserRouter> */}
        </>
      )
    }

export default NavigateInBagStoreRoom
