import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import RejectNewMould from './RejectNewMould'
import DataOfMouldRejection from './DataOfMouldRejection'
import Login from '../../assets/Login'
import Logout from '../../assets/Logout'

const NavigateInMouldRejection = () => {
  return (
    <>
      {/* <BrowserRouter> */}
        <Navbar moduleName={"Mould Rejection"} home={"/"} viewData={"/viewDataOfMouldRejection"} />
        <Routes>
          <Route path="/" element={<RejectNewMould />} />
          <Route path="/viewDataOfMouldRejection" element={< DataOfMouldRejection />} />
          <Route path="/myProfile" element={< ProfileCard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
   
      {/* </BrowserRouter> */}
    </>
  )
}

export default NavigateInMouldRejection
